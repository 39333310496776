import React, { useContext, useEffect, useRef, useState } from "react"
import AppointmentContext from "../context"
import { Form, FormControl, FormFeedback, FormGroup, Label } from "@components/Forms"
import Button from "@components/Buttons/Button"
import required from "@rules/required"
import moreThanDate from "@rules/moreThanDate"
import weekdays from "@rules/weekdays"
import rule from "@validator/rule"
import { encodeTimestamp } from "@src/heplers"
import { makeComponentStyles } from "@components/mixins"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import { MAKE_SERVICE_ORDER } from "@api/modules/requests"
import Alert from "@components/Dialog/Alert.jsx"
import { getStrapiContentByLang } from "@src/heplers.js"

const getAppointmentBySlug = (appointments, slug) => appointments.find(item => item.id === slug)
  || { id: null, nodes: [] }

const initBody = {
  specialist: null,
  date: "",
  time: ""
}

const styles = ({ palette, breakpoints }) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form__container: {
    margin: "0 auto",
    width: "50%",
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  error_message: {
    color: palette.error.main,
    textAlign: "center",
    marginBottom: 15
  }
})

const initErrors = {
  datetime: ""
}

function Order() {
  const { form, form__container, error_message } = makeComponentStyles(styles);
  const { appointments, setTitle, service } = useContext(AppointmentContext);
  const intl = useIntl()

  const group = getAppointmentBySlug(appointments, service);
  const appointment = getStrapiContentByLang(group.nodes, intl.locale);

  const [getSelectedSpecialist, setSelectedSpecialist] = useState(null)
  const [body, setBody] = useState(initBody)
  const [errors, setErrors] = useState(initErrors);
  const [alertState, setAlertState] = useState(false)

  useEffect(() => setTitle(appointment.title), [])
  useEffect(() => {
    setSelectedSpecialist(appointment.specialists[body.specialist])
  }, [body.specialist])

  const observeBody = (key, event) => {
    setBody(body => ({
      ...body,
      [key]: event.target.value
    }))
  }

  const handleCloseAlert = () => {
    navigate("/users/account")
  }

  const makeRequestBody = () => {

    const datetime = encodeTimestamp(body.date + "T" + body.time)

    const makeBodyByLang = (lang) => {
      const appointment = getStrapiContentByLang(group.nodes, lang);
      const selectedSpecialist = appointment.specialists[body.specialist];
      return {
        specialist: {
          name: selectedSpecialist.name
        },
        datetime,
        service: {
          title: appointment.title,
          audience: selectedSpecialist.audience
        }
      }
    }

    const getAppointmentLocalizations = (except) => {
      const localizations = {};
      group.nodes
        .filter(({ node }) => node.locale !== except)
        .forEach(({ node: { locale } }) => localizations[locale] = makeBodyByLang(locale))
      return localizations;
    }

    return {
      ...makeBodyByLang('ru-RU'),
      localizations: getAppointmentLocalizations('ru-RU')
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const requestBody = makeRequestBody();
    try {
      const { data } = await MAKE_SERVICE_ORDER(requestBody)
      setAlertState(true)
    } catch (err) {
      setErrors({
        ...initErrors,
        ...err.response.data.errors
      })
    }
  }

  if (!Object.values(appointment).length) return null;

  return (
    <div className={form__container}>
      <Form className={form} onSubmit={handleSubmit} validate>
        <FormGroup name={"specialist-input"}>
          <Label>{intl.formatMessage({ id: "teacher" })}</Label>
          <FormControl
            type={"select"}
            onChange={observeBody.bind(null, "specialist")}
            validations={[required]}
          >
            <option value="null">{intl.formatMessage({ id: "appointment_select_teacher" })}</option>
            {
              appointment.specialists.map((item, key) => (<option value={key} key={key}>{item.name}</option>))
            }
          </FormControl>
          <FormFeedback />
        </FormGroup>
        <FormGroup name={"date-input"} requestError={
          errors.datetime
            ? intl.formatMessage({ id: errors.datetime })
            : null
        }>
          <Label>{intl.formatMessage({ id: "date" })}</Label>
          <FormControl
            type={"date"}
            onChange={observeBody.bind(null, "date")}
            validations={[
              required,
              rule(moreThanDate, { value: "now", message: intl.formatMessage({ id: "date_err" }) }),
              rule(weekdays, { value: getSelectedSpecialist?.weekdays, message: intl.formatMessage({ id: "wrongSpecialistDate" }) })
            ]}
          />
          <FormFeedback />
        </FormGroup>
        <FormGroup name={"time-input"}>
          <Label>{intl.formatMessage({ id: "time" })}</Label>
          <FormControl
            type={"select"}
            onChange={observeBody.bind(null, "time")}
            validations={[required]}
          >
            <option value="null">{intl.formatMessage({ id: "appointment_select_time" })}</option>
            {
              getSelectedSpecialist
                ? getSelectedSpecialist.times.map((item, key) => {
                  const [hour, minute] = item.split(":");
                  const time = `${hour}:${minute}`;
                  return (<option value={time} key={key}>{time}</option>)
                })
                : ""
            }
          </FormControl>
          <FormFeedback />
        </FormGroup>
        <Button type="submit">{intl.formatMessage({ id: "save" })}</Button>
      </Form>
      <Alert
        state={alertState}
        setState={handleCloseAlert}
      >{intl.formatMessage({ id: "service_send" })}</Alert>
    </div>
  )
}

export default Order
