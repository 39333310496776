import React, {useContext, useEffect} from 'react'
import ButtonLink from "@components/Buttons/ButtonLink";
import ArrowRight from "@static/images/ArrowRight.svg"
import {makeComponentStyles} from "@components/mixins";
import AppointmentContext from '../context';
import {useIntl} from "gatsby-plugin-react-intl";
import { getStrapiContentByLang } from "@src/heplers.js"

const ButtonStyles = ({ palette }) => ({
    button__container: {
        cursor: "pointer",
        padding: "15px 24px",
        borderBottom: "solid 1px " + palette.text.muted,
        transition: "background .25s ease",
        "&:hover": {
            background: "rgba(0,0,0, .05)"
        }
    },
    button: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "18px !important"
    },
})

const AppointmentCard = ({ appointment, click }) => {
    const { button, button__container } = makeComponentStyles(ButtonStyles)
    return (
        <div onClick={click} className={button__container}>
            <ButtonLink className={button}>
                {appointment.title} <img src={ArrowRight} alt="arrow-right" />
            </ButtonLink>
        </div>
    )
}

const MailStyles = ({ palette, breakpoints }) => ({
    link__container: {
        margin: "0 -16px",
        [breakpoints.down('sm')]: {
            margin: "0 -24px"
        },
        borderTop: "solid 1px " + palette.text.muted
    }
})


function Index(){
    const { link__container } = makeComponentStyles(MailStyles)
    const intl = useIntl();
    const { appointments, setTitle, setService, service } = useContext(AppointmentContext);
    useEffect(() => setTitle(intl.formatMessage({id: "make_appointment"})), [])

    return (
        <div className={link__container}>
            {
                appointments
                  .map(({ id, nodes }) => ({  id, node: getStrapiContentByLang(nodes, intl.locale) }))
                  .map(({ id, node }) => (<AppointmentCard appointment={node} click={() => setService(id)}  />))
            }
        </div>
    )
}

export default Index;
