import React, {useContext} from 'react'
import {navigate} from "gatsby-plugin-react-intl";
import {observer} from "mobx-react-lite";
import AppointmentComponent from '@components/Account/pages/appointment';
import AuthContext from '@src/auth/context';
import { sortStrapiContentByLang } from "@src/heplers.js"
import { graphql } from "gatsby"

function Appointment({ data }){
    const { auth } = useContext(AuthContext);

    const appointments = sortStrapiContentByLang(data.allStrapiServices.edges, true);

    if(!auth.isAuth && !auth.isLoading){
        navigate("/users/login");
    }

    return (
        <AppointmentComponent appointments={appointments}/>
    )

}

export default observer(Appointment);
export const pageQuery = graphql`{
  allStrapiServices {
    edges {
      node {
        locale
        Title
        localizations {
          id
        }
        Specialist {
          Name
          Audience
          weekdays {
            day_of_week
          }
          SpecialistTimes {
            Time
          }
        }
        strapiId
      }
    }
  }
}`;
