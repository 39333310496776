import React, { useEffect, useState } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container";
import MainWrap from "@components/Wrap/MainWrap";
import {Typography} from "@material-ui/core";
import ButtonBack from "@components/Buttons/ButtonBack";
import AppointmentContext from './context';
import IndexAppointment from './pages/index'
import OrderAppointment from './pages/order'
import {observer} from "mobx-react-lite";
import { navigate } from "gatsby-plugin-react-intl";
import { useIntl } from "gatsby-plugin-react-intl"

const appointmentSerialize = (items) => {
    return items.map(({ node: { Specialist, Title, strapiId, locale } }) => {
        return {
            node: {
                title: Title,
                slug: strapiId,
                specialists: Specialist.map(({ Name, Audience, weekdays, SpecialistTimes }) => {
                    return {
                        name: Name,
                        audience: Audience,
                        weekdays: weekdays.map(({ day_of_week }) => day_of_week),
                        times: SpecialistTimes.map(({ Time }) => Time)
                    }
                }),
                locale
            }
        }
    });
}

function Index({ appointments }) {

    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({id: "make_appointment"}));
    const [service, setService] = useState(null);
    const [component, setComponent] = useState(<IndexAppointment/>);

    const context = {
        appointments: appointments.map(group => ({
            id: group.id,
            nodes: appointmentSerialize(group.nodes)
        })),
        setTitle,
        service,
        setService
    }

    const handleBack = () => {
        if(service) setService(null)
        else navigate("/users/account");
    }

    useEffect(() => {
        if (service !== null) setComponent(<OrderAppointment/>)
        else setComponent(<IndexAppointment/>)
    }, [service])


    return (
        <Layout>
            <Container>
                <MainWrap>
                    <MainWrap.Header>
                        <ButtonBack click={handleBack}/>
                        <Typography component={"h1"} variant={"h2"}>
                            {title}
                        </Typography>
                    </MainWrap.Header>
                    <MainWrap.Body>
                        <AppointmentContext.Provider value={context}>
                            {component}
                        </AppointmentContext.Provider>
                    </MainWrap.Body>
                </MainWrap>
            </Container>
        </Layout>
    )
}

export default observer(Index);
